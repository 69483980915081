import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Link } from 'gatsby'

import sozai from '../assets/images/sozai.jpg'

const Sozai = props => (
  <Layout>
    <Helmet>
      <title>About</title>
      <meta name="description" content="Project - Ecommerce" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>E-commerce website</h1>
          </header>
          <p>
            The project for a fictional plant store with user login, cart
            management, stripe payments, and a management console.
            <br />
            Built using Next.js, TypeScript and MongoDB. Inventory and users are
            stored on MongoDB/Firebase, items can be added to your own cart and
            a mock checkout can be done using Stripe test payments.
          </p>
          <tbody>
            <tr>
              <th>Type</th>
              <th>Stack</th>
              <th>Code</th>
              <th>Live</th>
            </tr>
            <tr>
              <td>Personal</td>
              <td>Next.js</td>
              <td class="ecommerce">
                <a
                  href="https://github.com/taekimura/nextjs-ecommerce"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Repository
                </a>
              </td>
              <td class="Decore__Bold-sc-1tbn2mo-3 gicxJS">
                <a
                  href="https://nextjs-plant-tae-ecommerce.vercel.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Site
                </a>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Typescript</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Tailwind CSS</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>MongoDB</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Firebase</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>Stripe</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <span className="image main">
            <img src={sozai} alt="" />
          </span>
          <ul className="actions2">
            <li>
              <Link to="/#projects">Back</Link>
            </li>
          </ul>
          <br />
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In non lorem sit amet elit placerat maximus. Pellentesque aliquam maximus risus, vel sed vehicula.</p>
                    <p>Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque venenatis dolor imperdiet dolor mattis sagittis. Praesent rutrum sem diam, vitae egestas enim auctor sit amet. Pellentesque leo mauris, consectetur id ipsum sit amet, fersapien risus, commodo eget turpis at, elementum convallis elit. Pellentesque enim turpis, hendrerit tristique lorem ipsum dolor.</p> */}
        </div>
      </section>
    </div>
  </Layout>
)

export default Sozai
